import React from 'react';
import ComingSoon from './components/ComingSoon'

class App extends React.Component {
  constructor() {
    super()
    this.state = {

    }
  }
  render() {
    return (
      <ComingSoon />
    )
  }
};

export default App;
