
import React from 'react';
import '../css/comingsoon.css'
import brightspaceslogo from './imgs/brightspaceslogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(
    fab
);

class ComingSoon extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    render() {
        return (
            <div id='container'>
                <img src={brightspaceslogo} className='logo' alt='brightspaces logo' />

                <div className='content'>
                    <h1 className='heading'> COMING <span>SOON!</span> </h1>
                    <p> We’re busy working on a new clean, bright space.
                    In the meantime, find us on
            
                        <span className='social-wrapper'>
                            <a href='https://www.facebook.com/Brightspacescleaningsolutions'>
                                <FontAwesomeIcon icon={['fab', 'facebook']} className='fb icon' />
                            </a>
                            <a href='https://www.instagram.com/brightspacesoc/'>
                                <FontAwesomeIcon icon={['fab', 'instagram']} className='insta icon' />
                            </a>
                            {/*
                            <a href='*'>
                                <FontAwesomeIcon icon={['fab', 'yelp']} className='yelp icon' />
                            </a>
                            */}
                        </span>
                    </p>
                </div>
            </div>
        )
    };
};

export default ComingSoon;
